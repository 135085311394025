import { ChevronDownIcon } from '@heroicons/react/24/outline';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { useMemo } from 'react';
import { type MenuItem } from '../types';
import styles from './nav-menu-item.module.css';
import { NavMenuLink } from './nav-menu-link';
import { getLocaleFromUrl, getPathnameFromUrl, useTranslatedPath } from '@/i18n';
import { useUrl } from '@/stores/url';
import { cn } from '@/utils/classnames';
import { resolveBaseUrl } from '@/utils/url';

interface NavMenuItemProps {
  level?: number;
  isChildItem?: boolean;
  menuItem: MenuItem;
}

export function NavMenuItem({ level = 0, menuItem, isChildItem }: NavMenuItemProps) {
  const url = useUrl();
  const pathname = getPathnameFromUrl(url);
  const locale = getLocaleFromUrl(url);
  const translatePath = useTranslatedPath(locale);
  const { link: baseLink, label, children = [] } = menuItem;

  const link = resolveBaseUrl(translatePath(baseLink ?? ''));

  const selected = useMemo(() => {
    if (link === pathname) {
      return true;
    }
    const child = children.find(({ link: childLink }) => childLink === pathname);
    return Boolean(child);
  }, [children, link, pathname]);

  if (children.length > 0) {
    return (
      <NavigationMenu.Item className="relative flex w-full flex-col items-center lg:flex-row">
        <NavigationMenu.Trigger asChild>
          <NavMenuLink element="button" className={styles.trigger} active={selected}>
            {label}
            <div className={cn(styles.caret, 'flex h-6 w-6 items-center justify-center')}>
              <ChevronDownIcon className="h-5 w-5" aria-hidden />
            </div>
          </NavMenuLink>
        </NavigationMenu.Trigger>
        <NavigationMenu.Content className="w-full lg:absolute lg:left-0 lg:top-20 lg:border-b lg:border-primary-400 lg:bg-primary-950 lg:px-6 lg:py-4">
          <div className="lg:container max-lg:w-full">
            <ul className="list-none lg:-mx-1.5 lg:grid lg:grid-cols-6 lg:gap-6 xl:gap-12">
              {children.map(child => (
                <li key={child.label}>
                  <NavMenuItem isChildItem menuItem={child} level={level + 1} />
                </li>
              ))}
            </ul>
          </div>
        </NavigationMenu.Content>
      </NavigationMenu.Item>
    );
  }

  return (
    <NavigationMenu.Item className="relative flex w-full flex-col items-center lg:flex-row">
      {link ? (
        <NavigationMenu.Link asChild>
          <NavMenuLink isChildItem={isChildItem} href={link} active={link === url.pathname}>
            {label}
          </NavMenuLink>
        </NavigationMenu.Link>
      ) : (
        label
      )}
    </NavigationMenu.Item>
  );
}
