import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { type ComponentPropsWithoutRef } from 'react';
import { type MenuItem } from '../types';
import { NavMenuItem } from './nav-menu-item';
import { LanguageSwitcherMobile } from '@/components/language-switcher/language-switcher-mobile';
import { cn } from '@/utils/classnames';

interface MenuProps extends ComponentPropsWithoutRef<typeof NavigationMenu.Root> {
  menuItems: MenuItem[];
  isVisible?: boolean;
  mobile?: boolean;
}

export default function NavMenu({ className, menuItems, isVisible, mobile, ...rest }: MenuProps) {
  return (
    <NavigationMenu.Root
      delayDuration={0}
      className={cn(
        mobile ? (isVisible ? 'flex lg:hidden' : 'hidden') : 'hidden lg:flex',
        'lg:ml-8 lg:flex-grow lg:items-center',
        className,
      )}
      {...rest}
    >
      <div className="max-lg:flex max-lg:w-full max-lg:flex-col max-lg:gap-4 max-lg:bg-primary-950 max-lg:p-6">
        <LanguageSwitcherMobile />
        <NavigationMenu.List className="flex list-none flex-col items-center gap-2 lg:flex-row lg:gap-4">
          {menuItems.map(menuItem => (
            <NavMenuItem key={menuItem.label} menuItem={menuItem} />
          ))}
        </NavigationMenu.List>
      </div>
      {!mobile && <NavigationMenu.Viewport />}
    </NavigationMenu.Root>
  );
}
