import { useStore } from '@nanostores/react';
import NavMenu from './components/nav-menu';
import { isMenuOpen } from './store';
import { type MenuItem } from './types';

export interface MenuProps {
  menuItems: MenuItem[];
  mobile?: boolean;
}

export function Menu({ menuItems, mobile }: MenuProps) {
  const isMenuVisible = useStore(isMenuOpen);

  return <NavMenu menuItems={menuItems} isVisible={isMenuVisible} mobile={mobile} />;
}
