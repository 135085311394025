/**
 * Returns a URL pathname appended with the baseUrl set in Astro config.
 *
 * @example
 * ```ts
 * resolveBaseUrl('/hello')
 * // -> /base/hello
 * ```
 *
 * This can also be combined with other URL utils, e.g. `translatePath()`:
 *
 * ```ts
 * resolveBaseUrl(translatePath('/hello'))
 * // -> /base/en/hello
 * ```
 *
 * @param pathParam The path param to append the `baseUrl` to
 * @returns A url path with the `baseUrl` appended.
 */
export function resolveBaseUrl(pathParam: string) {
  let path = pathParam;
  if (!path.startsWith('/')) {
    path = `/${path}`;
  }

  const baseUrl = import.meta.env.BASE_URL;
  if (baseUrl === '/') {
    return `${path}`;
  }

  return `${baseUrl}${path}`;
}
