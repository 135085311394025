import { AnchorButton, type AnchorButtonProps } from '../../ui/button';
import { cn } from '@/utils/classnames';

export interface LanguageSwitcherButtonProps extends AnchorButtonProps {
  isActiveLocale: boolean;
  mobile?: boolean;
}

export function LanguageSwitcherButton({
  className: classNameFromProps,
  isActiveLocale,
  mobile,
  ...rest
}: LanguageSwitcherButtonProps) {
  return (
    <AnchorButton
      size="sm"
      variant={isActiveLocale ? 'primary' : 'primary-outline'}
      className={cn(
        'justify-center',
        {
          'w-10': !mobile,
        },
        classNameFromProps,
      )}
      {...rest}
    />
  );
}
