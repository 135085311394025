import { LanguageSwitcherButton } from './components/language-switcher-button';
import { getLocaleFromUrl, getPathnameFromUrl, useTranslatedPath } from '@/i18n';
import { useUrl } from '@/stores/url';

export function LanguageSwitcherMobile() {
  const url = useUrl();
  const lang = getLocaleFromUrl(url);
  const pathname = getPathnameFromUrl(url);
  const translatePath = useTranslatedPath(lang);

  return (
    <div className="flex items-center lg:hidden">
      <LanguageSwitcherButton
        className="border-r-none flex-1 rounded-r-none"
        href={translatePath(pathname, 'id')}
        isActiveLocale={lang === 'id'}
      >
        Bahasa Indonesia
      </LanguageSwitcherButton>
      <LanguageSwitcherButton
        className="border-l-none flex-1 rounded-l-none"
        href={translatePath(pathname, 'en')}
        isActiveLocale={lang === 'en'}
      >
        English
      </LanguageSwitcherButton>
    </div>
  );
}
