import { forwardRef, type ComponentPropsWithoutRef } from 'react';
import { cn } from '@/utils/classnames';

type MergedButtonTypes = ComponentPropsWithoutRef<'a'> & ComponentPropsWithoutRef<'button'>;

export interface NavMenuLinkProps extends MergedButtonTypes {
  element?: 'a' | 'button';
  isChildItem?: boolean;
  active?: boolean;
}

export const NavMenuLink = forwardRef<HTMLAnchorElement & HTMLButtonElement, NavMenuLinkProps>(
  ({ children, className, active, element: Element = 'a', isChildItem, ...rest }, ref) => {
    return (
      <Element
        ref={ref}
        className={cn(
          'flex justify-between rounded font-medium',
          isChildItem
            ? 'w-full gap-2.5 px-2.5 py-1.5 text-sm lg:px-2.5'
            : 'w-max gap-2.5 px-2.5 py-2.5 text-base max-lg:w-full',
          {
            'bg-primary-400 text-black': active,
            'text-white hover:bg-white hover:text-black': !active,
          },
          className,
        )}
        {...rest}
      >
        {children}
      </Element>
    );
  },
);
